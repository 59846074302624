import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import useMyFamily from './UseMyFamily';

import Child from './Child';

import RenewMemberships from '../../../../../common/components/renewMemberships/RenewMemberships';

import Spinner from '../../../../../common/components/spinners/Spinner';
import UserPlusIcon from '../../../../../common/components/icons/UserPlusIcon';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
// import Input from '../../../../../common/components/inputs/Input';
import UserContactMultiSelect from '../../../../../common/components/multiSelect/userContact/UserContactMultiSelect';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Acknowledgements from '../../../../../common/components/acknowledgements/Acknowledgements';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';

import style from './../../LandingPages.module.css';
import global from '../../../../../common/components/GlobalStyle.module.css';

const AddFamilyMemberPopup = ({ uiState, isSubmitting, formState, errorState, onFormValueChanged,
  //  onSaveFamilyMemberClicked,
  onSaveContactToFamilyClicked, onAddFamilyMemberModalCanceled, personState }) => (
  <PopUpModal
    widthPct={90}
    maxWidth={400}
    title={'Add Family Member'}
    displayPopUp={uiState?.addClicked === true && isSubmitting === false}
    onModalCanceled={onAddFamilyMemberModalCanceled} >
    <div className="row">
      <div className="col-xs-12 usas-extra-bottom-margin">
        <Headings.H5>Add Contact(s) to My Family</Headings.H5>
      </div>
      <div className="col-xs-12">
        <UserContactMultiSelect
          label={'Contact(s)*'}
          name={'userContact'}
          value={formState.userContact}
          error={errorState.userContact}
          message={errorState.userContact}
          onChange={(value) => { onFormValueChanged('userContact', value); }}
          personId={personState.objData.personId || Constants.DEFAULT_ID} />
      </div>
      <div className="col-xs-12 usas-extra-top-margin">
        <span><b>If the person you wish to add isn&apos;t listed above (click on the &nbsp;<FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>&nbsp; to show the list), please reach out to their club for assistance with updating their contact information.</b></span>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="button" onClick={onSaveContactToFamilyClicked}>Save</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={onAddFamilyMemberModalCanceled}>Cancel</SecondaryButton>
      </div>
    </div>
    {/* <hr></hr>
    <div className="row">
      <div className="col-xs-12 usas-extra-bottom-margin">
        <Headings.H5>Add an Existing USA Swimming Member to Your Family by Their Member Id. Don&apos;t Know Their Member Id? Please Contact Their Club.</Headings.H5>
      </div>
    </div>
    <form>
      <div className="row">
        <div className="col-xs-12">
          <Input
            label="Member Id*"
            name="memberId"
            value={formState.memberId}
            error={errorState.memberId}
            message={errorState.memberId}
            onChange={(value) => { onFormValueChanged('memberId', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveFamilyMemberClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onAddFamilyMemberModalCanceled}>Cancel</SecondaryButton>
        </div>
      </div>
    </form> */}
  </PopUpModal>)

const MyFamily = () => {
  const {
    children,
    isLoading,
    isSubmitting,
    isSaving,
    uiState,
    formState,
    errorState,
    // hasUnpaidRegistrations,
    landingPageState,
    acknowledgementsModalState,
    personState,
    onFormValueChanged,
    onAddFamilyMemberClicked,
    onSaveFamilyMemberClicked,
    onSaveContactToFamilyClicked,
    onAddFamilyMemberModalCanceled,
    onAcknowledgmentsModalCanceled } = useMyFamily();

  if (isLoading === true) {
    return <Spinner />;
  } else {
    return (
      <Fragment>
        <div className={style.SectionBorder}>
          <div className={style.SectionTitle}>My Family<button type="button" className={global.IconButton} onClick={onAddFamilyMemberClicked}>Add Family Member&nbsp;&nbsp;<UserPlusIcon /></button></div>
          {/* {hasUnpaidRegistrations === true && <div className={style.SectionSubTitle}>
            <strong>
              NOTE: You have unpaid registrations in your shopping cart.  They will not become active until they have been paid.  Please click on the cart icon at the top of the page.
            </strong>
          </div>} */}
          {children && children.length > 0 ? (
            <Fragment>
              <div className={style.SectionSubTitle}>(Login accounts can be created for 8-17 year old family members)</div>
              <table className={style.Table}>
                <tbody>
                  {children.map((child, i) => <Child child={child} key={i} />)}
                </tbody>
              </table>
            </Fragment>
          ) : (
            <table className={style.Table}>
              <tbody>
                <tr>
                  <td style={{ paddingLeft: "15px", paddingTop: "15px" }} colSpan="3">No Family Members</td>
                </tr>
              </tbody>
            </table>
          )}
          <RenewMemberships omrView={false} myFamilyView={true} landingPageState={landingPageState} />
        </div>
        <AddFamilyMemberPopup uiState={uiState} formState={formState} errorState={errorState} onFormValueChanged={onFormValueChanged}
          onSaveFamilyMemberClicked={onSaveFamilyMemberClicked} onSaveContactToFamilyClicked={onSaveContactToFamilyClicked}
          onAddFamilyMemberModalCanceled={onAddFamilyMemberModalCanceled} isSubmitting={isSubmitting} personState={personState} />
        {acknowledgementsModalState.displayPopUp === true &&
          <PopUpModal
            widthPct={90}
            maxWidth={1400}
            title={acknowledgementsModalState.modalTitle}
            showModal={acknowledgementsModalState.displayPopUp}
            onModalCanceled={onAcknowledgmentsModalCanceled}>
            <Acknowledgements memberId={formState.newMemberId ? formState.newMemberId : formState.memberId} addANewFamilyMemberView={true} />
          </PopUpModal>}
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={isSubmitting} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={isSaving}
        />
      </Fragment>
    );
  }
};

export default MyFamily;