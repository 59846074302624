import { createContext, useState } from 'react';

import PersonParentChildFamilyContactData from './PersonParentChildFamilyContactData';

export const PersonParentChildFamilyContactStateContext = createContext();

const PersonParentChildFamilyContactContextProvider = ({ children }) => {
  const stateHook = useState(PersonParentChildFamilyContactData.INITIAL_PERSON_PARENT_CHILD_FAMILY_CONTACT_STATE);

  return (
    <PersonParentChildFamilyContactStateContext.Provider value={stateHook}>
      {children}
    </PersonParentChildFamilyContactStateContext.Provider>
  );
};

export default PersonParentChildFamilyContactContextProvider;