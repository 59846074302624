import { useContext } from 'react';

import PersonParentChildFamilyContactData from './PersonParentChildFamilyContactData';

import { PersonParentChildFamilyContactStateContext } from './PersonParentChildFamilyContactContextProvider';

const usePersonParentChildFamilyContactData = () => {
  const [personParentChildFamilyContactState, setPersonParentChildFamilyContactState] = useContext(PersonParentChildFamilyContactStateContext);

  const getPersonParentChildFamilyContactsByPersonId = (personId) => {
    return PersonParentChildFamilyContactData.getPersonParentChildFamilyContactData(personId, personParentChildFamilyContactState, setPersonParentChildFamilyContactState);
  };

  const postPersonParentChildFamilyContactsByPersonId = (personId, familyContactsArray) => {
    return PersonParentChildFamilyContactData.postPersonParentChildFamilyContactData(personId, familyContactsArray, personParentChildFamilyContactState, setPersonParentChildFamilyContactState);
  };

  const resetPersonParentChildFamilyContactState = () => {
    setPersonParentChildFamilyContactState({
      ...PersonParentChildFamilyContactStateContext.INITIAL_PERSON_PARENT_CHILD_FAMILY_CONTACT_STATE
    });
  };

  return {
    personParentChildFamilyContactState,
    setPersonParentChildFamilyContactState,
    getPersonParentChildFamilyContactsByPersonId,
    postPersonParentChildFamilyContactsByPersonId,
    resetPersonParentChildFamilyContactState
  };
};

export default usePersonParentChildFamilyContactData;