import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const INITIAL_PERSON_PARENT_CHILD_FAMILY_CONTACT_STATE = {
  ...BASIC_INITIAL_STATE
};
const getPersonParentChildFamilyContactData = (personId, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/PersonParentChild/person/${personIdForUrl}/familyContact`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const postPersonParentChildFamilyContactData = (personId, familyContactsArray, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/PersonParentChild/person/${personIdForUrl}/familyContact`;

    return api?.executeArray ? api.executeArray(url, 'POST', familyContactsArray) : null;
  }
};

const PersonParentChildFamilyContactData = {
  INITIAL_PERSON_PARENT_CHILD_FAMILY_CONTACT_STATE,
  getPersonParentChildFamilyContactData,
  postPersonParentChildFamilyContactData
};

export default PersonParentChildFamilyContactData;