import PersonContextProvider from './state/landingPages/person/PersonContextProvider';
import ChildrenContextProvider from './state/landingPages/children/ChildrenContextProvider';
import PersonParentChildContextProvider from './state/landingPages/personParentChild/PersonParentChildContextProvider';
import CommunicationsHubContextProvider from './state/communicationsHub/CommunicationsHubContextProvider';

import VendorManagementContextProvider from './state/vendorManagement/VendorManagementContextProvider';
import UserGroupContextProvider from './state/userGroup/UserGroupContextProvider';
import AccountManagementUserGroupContextProvider from './state/accountManagement/accountManagementUserGroup/AccountManagementUserGroupContextProvider';

import SystemMessageContextProvider from '../common/state/systemMessages/SystemMessageContextProvider';
import ManageUserAccountsContextProvider from '../common/state/manageUserAccounts/ManageUserAccountsContextProvider';
import ManageUserAccountsFilterContextProvider from '../common/state/manageUserAccounts/ManageUserAccountsFilterContextProvider';
import RenewalOptionContextProvider from '../common/state/renewalOption/RenewalOptionContextProvider';
import PersonAcknowledgementAddAFamilyMemberContextProvider from '../common/state/personAcknowledgementAddAFamilyMember/PersonAcknowledgementAddAFamilyMemberContextProvider';
import BasePersonContextProvider from '../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../common/state/personBase/BasePersonFiltersContextProvider';
import PersonAcknowledgementInfoContextProvider from '../common/state/personAcknowledgementInfo/PersonAcknowledgementInfoContextProvider';
import PersonAcknowledgementInfoPageViewContextProvider from '../common/state/personAcknowledgementInfo/PersonAcknowledgementInfoPageViewContextProvider';
import PersonGeneralContextProvider from '../common/state/personGeneral/PersonGeneralContextProvider';
import PaymentsByCheckContextProvider from '../common/state/paymentsByCheck/PaymentsByCheckContextProvider';
import PersonParentChildFamilyContactContextProvider from '../common/state/personParentChildFamilyContact/PersonParentChildFamilyContactContextProvider';

const AppStateContextProviders = ({ children }) => {

  return (
    <PersonAcknowledgementAddAFamilyMemberContextProvider>
      <BasePersonContextProvider>
        <BasePersonFiltersContextProvider>
          <PersonParentChildFamilyContactContextProvider>
            <PersonGeneralContextProvider>
              <PersonAcknowledgementInfoContextProvider>
                <PersonAcknowledgementInfoPageViewContextProvider>
                  <RenewalOptionContextProvider>
                    <SystemMessageContextProvider>
                      <PersonContextProvider>
                        <ChildrenContextProvider>
                          <PersonParentChildContextProvider>
                            <CommunicationsHubContextProvider>
                              <AccountManagementUserGroupContextProvider>
                                <UserGroupContextProvider>
                                  <VendorManagementContextProvider>
                                    <ManageUserAccountsContextProvider>
                                      <ManageUserAccountsFilterContextProvider>
                                        <PaymentsByCheckContextProvider>
                                          {children}
                                        </PaymentsByCheckContextProvider>
                                      </ManageUserAccountsFilterContextProvider>
                                    </ManageUserAccountsContextProvider>
                                  </VendorManagementContextProvider>
                                </UserGroupContextProvider>
                              </AccountManagementUserGroupContextProvider>
                            </CommunicationsHubContextProvider>
                          </PersonParentChildContextProvider>
                        </ChildrenContextProvider>
                      </PersonContextProvider>
                    </SystemMessageContextProvider>
                  </RenewalOptionContextProvider>
                </PersonAcknowledgementInfoPageViewContextProvider>
              </PersonAcknowledgementInfoContextProvider>
            </PersonGeneralContextProvider>
          </PersonParentChildFamilyContactContextProvider>
        </BasePersonFiltersContextProvider>
      </BasePersonContextProvider>
    </PersonAcknowledgementAddAFamilyMemberContextProvider >
  );
};

export default AppStateContextProviders;