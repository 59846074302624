import global from '../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../utils/DateFunctions';

const RenewMembershipsSuccessSmallGrid = ({ gridData, isLoading }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((membership, i) => (
          <div className={global.SmallTableRow} key={'personId' + membership.personId + 'offeringInstanceId' + membership.offeringInstanceId}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''}  ${membership.preferredName !== '' && membership.preferredName !== membership.firstName ? '"' + membership.preferredName + '"' : ''} ${membership.middleName || ''} ${membership.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''}  ${membership.preferredName !== '' && membership.preferredName !== membership.firstName ? '"' + membership.preferredName + '"' : ''} ${membership.middleName || ''} ${membership.lastName || ''}` : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.competitionGenderTypeName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Birth Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.birthDate ? formatDate(membership.birthDate) : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Club Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.renewalOrgUnitName || <span>&nbsp;</span>} {membership.renewalOrgUnitName === 'Unattached' ? ' - ' + membership.renewalParentOrgUnitName : ''} {membership.renewalParentOrgUnitCode ? '(' + membership.renewalParentOrgUnitCode + ')' : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Renewed Membership</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.renewalOfferingName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Expires</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.renewalExpirationDate ? `${formatDate(membership.renewalExpirationDate)}` : <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Memberships were Renewed</div>
            </div>
          </div>
        )
    }
  </div >
);

export default RenewMembershipsSuccessSmallGrid;