import useUserContactMultiSelect from './UseUserContactMultiSelect';

import MultiSelect from '../../multiSelect/MultiSelect';

import global from '../../GlobalStyle.module.css';

const UserContactMultiSelect = ({ label, name, value, error, message, onChange, personId }) => {
  const { personParentChildFamilyContactState, options } = useUserContactMultiSelect(personId);

  return personParentChildFamilyContactState.message
    ? <span className={global.LoadingMsg}>{personParentChildFamilyContactState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={options}
        error={error}
        message={message}
        isLoading={personParentChildFamilyContactState.isObjLoading}
        onChange={onChange} />
    );
};

export default UserContactMultiSelect;