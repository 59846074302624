import { Fragment } from 'react';

import LeftCheckboxRenewMembershipsSelectionLargeGrid from './LeftCheckboxRenewMembershipsSelectionLargeGrid';
import RenewMembershipsSelectionSmallGrid from './RenewMembershipsSelectionSmallGrid';

const LeftCheckboxRenewMembershipsSelectionGrid = ({ omrView, gridData, isLoading, onCheckboxChange, onChangeMemberTypeClicked, onShowRenewalConfirmationModalClicked }) => {
  return (
    <Fragment>
      <LeftCheckboxRenewMembershipsSelectionLargeGrid
        omrView={omrView}
        gridData={gridData}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
        onChangeMemberTypeClicked={onChangeMemberTypeClicked}
        onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked}
      />
      <RenewMembershipsSelectionSmallGrid
        omrView={omrView}
        gridData={gridData}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
        onChangeMemberTypeClicked={onChangeMemberTypeClicked}
        onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked}
      />
    </Fragment>
  )
};

export default LeftCheckboxRenewMembershipsSelectionGrid;