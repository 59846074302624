import { useEffect, useState } from 'react';

import usePersonParentChildFamilyContactData from '../../../state/personParentChildFamilyContact/UsePersonParentChildFamilyContactData';

const useUserContactMultiSelect = (personId) => {
  const { personParentChildFamilyContactState, getPersonParentChildFamilyContactsByPersonId } = usePersonParentChildFamilyContactData()
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (personId
      && personParentChildFamilyContactState.isArrayLoading === false
      && personParentChildFamilyContactState.isArrayLoaded === false) {
      getPersonParentChildFamilyContactsByPersonId(personId);
    } else if (personParentChildFamilyContactState.isArrayLoading === false
      && personParentChildFamilyContactState.isArrayLoaded === true) {
      const options = [];
      for (const userContact of personParentChildFamilyContactState.arrayData) {
        options.push({ id: userContact.personId, name: `${userContact.firstName || ''} ${userContact.lastName || ''}` });
      }

      setOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId, personParentChildFamilyContactState]);

  return {
    personParentChildFamilyContactState,
    options
  };
};

export default useUserContactMultiSelect;